import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#e8f6fb',
            dark: '#1f2421',
        },
        secondary: {
            light: '#e8f6fb',
            main: '#047299',
            dark: '#007299',
        },
        warning: {
            main: '#fec646',
            dark: '#ffb25e',
        },
        error: {
            xLight: red[50],
            main: red[500],
            dark: red[700],
        },
        success: {
            xLight: green[50],
            main: green[500],
            dark: green[700],
        },
    },
    typography: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 16,
        fontWeightLight: 500, // Work Sans
        fontWeightRegular: 400, // Work Sans
        fontWeightMedium: 500, // Roboto Condensed
        fontWeightBold: 600,
        fontFamilySecondary: "'Enriqueta', sans-serif",
    },
});

const fontHeader = {
    color: rawTheme.palette.text.primary,
    fontWeight: rawTheme.typography.fontWeightBold,
    fontFamily: rawTheme.typography.fontFamilySecondary,
};

const theme = {
    ...rawTheme,
    palette: {
        ...rawTheme.palette,
        background: {
            ...rawTheme.palette.background,
            default: rawTheme.palette.common.white,
            placeholder: grey[200],
        },
    },
    typography: {
        ...rawTheme.typography,
        fontHeader,
        h1: {
            ...rawTheme.typography.h1,
            ...fontHeader,
            letterSpacing: 5,
            fontSize: 60,
        },
        h2: {
            ...rawTheme.typography.h2,
            ...fontHeader,
            fontWeightBold: 700,
            fontSize: 48,
        },
        h3: {
            ...rawTheme.typography.h3,
            ...fontHeader,
            fontSize: 42,
        },
        h4: {
            ...rawTheme.typography.h4,
            ...fontHeader,
            fontSize: 32,
        },
        h5: {
            ...rawTheme.typography.h5,
            fontSize: 20,
            fontWeight: rawTheme.typography.fontWeightLight,
        },
        h6: {
            ...rawTheme.typography.h6,
            ...fontHeader,
            fontSize: 18,
        },
        subtitle1: {
            ...rawTheme.typography.subtitle1,
            fontSize: 18,
        },
        body1: {
            ...rawTheme.typography.body2,
            fontWeight: rawTheme.typography.fontWeightRegular,
            fontSize: 17,
        },
        body2: {
            ...rawTheme.typography.body1,
            fontSize: 14,
        },
        p: {
            ...rawTheme.typography.p,
            fontSize: 17,
        }
    },
};

export default theme;